import React from 'react'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Layout from 'components/Layout'
import Link from 'components/Link'
import SectionGetStarted from 'components/SectionGetStarted'

const popover = (id, text) => <Popover id={id}>{text}</Popover>

const PricingPage = ({ location }) => (
  <Layout
    title="Pentafile Precios, planes a tu medida y con todas las características"
    description="Precios flexibles para ti, los mejores planes pensando en tu crecimiento"
    location={location}
  >
    <section className="section">
      <div className="container">
        <h1 className="text-center mb-xs">
          Prueba Pentafile e inicia con el plan gratis
        </h1>
        <h4 className="text-center font-light">
          Configuración fácil. Integra en minutos. No requiere tarjeta de
          crédito.
        </h4>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <div className="flex-content-center pricing-plan-container">
          <div className="pricing-plan text-center">
            <div className="pricing-body">
              <div>
                <div className="pricing-icon">
                  <img src="/assets/images/pricing-basic.png" />
                </div>
                <div className="pricing-name">Gratis</div>
                <div className="pricing-charge">
                  $/. 0<sub>/Mes</sub>
                </div>
                <div className="pricing-description">
                  <p>Hasta 5 GB de almacenamiento</p>
                  <p>Carga de archivo limitado al almacenamiento</p>
                  <p>Componente UI Javascript</p>
                  <p>Múltiples clientes SDK</p>
                  <p>Entrega de contenido CDN</p>
                  <p>Tranformación de imágenes</p>
                  <p>Acceso restringido por dominios</p>
                  <p>Seguridad URL Signed</p>
                  <p><del>Configurar proveedor de almacenamiento</del></p>
                  <p><del>Notificaciones periodicas</del></p>
                  <p><del>Dominio personalizado</del></p>
                </div>
              </div>

              <div className="pricing-notes">
                <Link
                  data-track
                  data-track-location="pricing-lite"
                  data-track-event="Start Trial"
                  to="https://console.pentafile.com?plan=FREE"
                  className="btn btn-primary"
                >
                  Probar
                </Link>
              </div>
            </div>
          </div>

          <div className="pricing-plan text-center">
            <div className="pricing-body">
              <div>
                <div className="pricing-icon">
                  <img src="/assets/images/pricing-starter.png" />
                </div>
                <div className="pricing-name">Básico</div>
                <div className="pricing-charge">
                  $5<sub>/Mes</sub>
                </div>
                <div className="pricing-description">
                  <p>Hasta 50 GB de almacenamiento</p>
                  <p>Carga de archivo limitado al almacenamiento</p>
                  <p>Componente UI Javascript</p>
                  <p>Múltiples clientes SDK</p>
                  <p>Entrega de contenido CDN</p>
                  <p>Tranformación de imágenes</p>
                  <p>Acceso restringido por dominios</p>
                  <p>Seguridad URL Signed</p>
                  <p>Configurar proveedor de almacenamiento</p>
                  <p><del>Notificaciones periodicas</del></p>
                  <p><del>Dominio personalizado</del></p>
                </div>
              </div>

              <div className="pricing-notes">
                <Link
                  disabled
                  data-track
                  data-track-location="pricing-business"
                  data-track-event="Start Trial"
                  to=""
                  className="btn btn-secondary"
                >
                  Beta
                </Link>
              </div>
            </div>
          </div>

          <div className="pricing-plan text-center">
            <div className="pricing-body">
              <div>
                <div className="pricing-icon">
                  <img src="/assets/images/pricing-pro.png" />
                </div>
                <div className="pricing-name">Avanzado</div>
                <div className="pricing-charge">
                  $19<sub>/Mes</sub>
                </div>
                <div className="pricing-description">
                  <p>Hasta 200 GB de almacenamiento</p>
                  <p>Carga de archivo limitado al almacenamiento</p>
                  <p>Componente UI Javascript</p>
                  <p>Múltiples clientes SDK</p>
                  <p>Entrega de contenido CDN</p>
                  <p>Tranformación de imágenes</p>
                  <p>Acceso restringido por dominios</p>
                  <p>Seguridad URL Signed</p>
                  <p>Configurar proveedor de almacenamiento</p>
                  <p><del>Notificaciones periodicas</del></p>
                  <p><del>Dominio personalizado</del></p>
                </div>
              </div>

              <div className="pricing-notes">
                <Link
                  disabled
                  data-track
                  data-track-location="pricing-business"
                  data-track-event="Start Trial"
                  to=""
                  className="btn btn-secondary"
                >
                  Beta
                </Link>
              </div>
            </div>
          </div>
          <div className="pricing-plan text-center">
            <div className="pricing-body">
              <div>
                <div className="pricing-icon">
                  <img src="/assets/images/pricing-business.png" />
                </div>
                <div className="pricing-name">OEM</div>
                <div className="pricing-charge">Flexible</div>
                <div className="pricing-description">
                  <p>Ilimitado GB de almacenamiento</p>
                  <p>Carga de archivo limitado al almacenamiento</p>
                  <p>Componente UI Javascript</p>
                  <p>Múltiples clientes SDK</p>
                  <p>Entrega de contenido CDN</p>
                  <p>Tranformación de imágenes</p>
                  <p>Acceso restringido por dominios</p>
                  <p>Seguridad URL Signed</p>
                  <p>Configurar proveedor de almacenamiento</p>
                  <p>Notificaciones periodicas</p>
                  <p>
                  Dominio{' '}
                    <OverlayTrigger
                      trigger={['hover', 'focus']}
                      placement="top"
                      overlay={popover(
                        2,
                        'Se configura al dominio del cliente (http://cdn.midominio.com).'
                      )}
                    >
                      <span className="dotted-helper">personalizado</span>
                    </OverlayTrigger>
                  </p>
                </div>
              </div>
              <div className="pricing-notes">
                <Link
                  disabled
                  data-track
                  data-track-location="pricing-business"
                  data-track-event="Start Trial"
                  to=""
                  className="btn btn-secondary"
                >
                  Beta
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center m-t-25">
            <i className="silver">
              Política de uso justo: Nos reservamos el derecho de contactar y
              limitar a los usuarios que excedan lo que consideramos uso justo.
            </i>
          </div>
        </div>
      </div>
    </section>
    <SectionGetStarted />
  </Layout>
)

export default PricingPage
